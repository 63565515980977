import { LocalesType } from '../Interface/LocalesInterface';

export const getLocaleToBeUsed = (
  locale: string | null | undefined
): LocalesType => {
  if (
    locale === 'en' ||
    locale === 'es' ||
    locale === 'de' ||
    locale === 'nl' ||
    locale === 'it' ||
    locale === 'fr' ||
    locale === 'hu' ||
    locale === 'pl' ||
    locale === 'da' ||
    locale === 'fi' ||
    locale === 'sv' ||
    locale === 'pt' ||
    locale === 'br' ||
    locale == 'id' ||
    locale === 'th' ||
    locale === 'vi' ||
    locale === 'cs' ||
    locale === 'el' ||
    locale === 'tl' ||
    locale === 'ro' ||
    locale === 'ru' ||
    locale === 'tr' ||
    locale === 'lt' ||
    locale === 'zh'
  ) {
    return locale;
  }
  return 'en';
};

export const getLanguageFromLocale = (
  locale: string | null | undefined
): string => {
  switch (locale) {
    case 'es':
      return 'Spanish';
    case 'de':
      return 'German';
    case 'en':
      return 'English';
    case 'nl':
      return 'Dutch';
    case 'it':
      return 'Italian';
    case 'fr':
      return 'French';
    case 'hu':
      return 'Hungarian';
    case 'pl':
      return 'Polish';
    case 'sv':
      return 'Swedish';
    case 'pt':
      return 'Portuguese';
    case 'da':
      return 'Danish';
    case 'fi':
      return 'Finnish';
    case 'br':
      return 'Brazilian Portuguese';
    case 'id':
      return 'Indonesian';
    case 'th':
      return 'Thai';
    case 'vi':
      return 'Vietnamese';
    case 'cs':
      return 'Czech';
    case 'el':
      return 'Greek';
    case 'tl':
      return 'Filipino';
    case 'ro':
      return 'Romanian';
    case 'ru':
      return 'Russian';
    case 'tr':
      return 'Turkish';
    case 'lt':
      return 'Lithuanian';
    case 'zh-cn':
      return 'Simplified Chinese';
  }
  return 'English';
};

export const getLocaleForCountry = (
  country: string | null | undefined
): LocalesType => {
  switch (country) {
    case 'DE':
      return 'de';
    case 'ES':
      return 'es';
    case 'MX':
      return 'es';
    case 'CO':
      return 'es';
    case 'AR':
      return 'es';
    case 'VE':
      return 'es';
    case 'PE':
      return 'es';
    case 'CL':
      return 'es';
    case 'EC':
      return 'es';
    case 'CU':
      return 'es';
    case 'GT':
      return 'es';
    case 'DO':
      return 'es';
    case 'HN':
      return 'es';
    case 'BO':
      return 'es';
    case 'SV':
      return 'es';
    case 'NI':
      return 'es';
    case 'NL':
      return 'nl';
    case 'IT':
      return 'it';
    case 'FR':
      return 'fr';
    case 'HU':
      return 'hu';
    case 'SE':
      return 'sv';
    case 'PT':
      return 'pt';
    case 'DK':
      return 'da';
    case 'FI':
      return 'fi';
    case 'BR':
      return 'br';
    case 'VN':
      return 'vi';
    case 'TH':
      return 'th';
  }
  return 'en';
};
